import { Pause as PauseIcon, PlayFilled as PlayIcon } from '@air/next-icons';
import { memo } from 'react';

import { DarkIconButton } from '~/components/AssetModal/shared/components/DarkIconButton';
import { PLAY_PAUSE_BUTTON } from '~/constants/testIDs';

interface PlayPauseButtonProps {
  onPlayPause: () => void;
  isPlaying: boolean;
}
export const PlayPauseButton = memo(({ onPlayPause, isPlaying }: PlayPauseButtonProps) => {
  return (
    <DarkIconButton
      testId={PLAY_PAUSE_BUTTON}
      tx={{
        ml: 4,
        mr: [0, 8],
      }}
      onClick={onPlayPause}
      icon={isPlaying ? PauseIcon : PlayIcon}
    >
      {isPlaying ? 'Pause' : 'Play'}
    </DarkIconButton>
  );
});

PlayPauseButton.displayName = 'PlayPauseButton';
