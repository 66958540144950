import { Box } from '@air/zephyr';
import classNames from 'classnames';
import { memo, useMemo } from 'react';

import { FileExtensionName, fileExtensionStylingMap, genericFile } from '~/components/FileExtensionPreview/types';

import { THUMBNAIL_HEIGHT } from '../../../shared/constants';

interface VersionFileThumbnailProps {
  ext?: string;
  isCurrentVersion: boolean;
}

export const VersionFileThumbnail = memo(({ ext, isCurrentVersion }: VersionFileThumbnailProps) => {
  const extStyles = useMemo(() => {
    const normalizedExt = ext?.toLowerCase() || ''; // to support e.g. JPG
    return normalizedExt in fileExtensionStylingMap
      ? fileExtensionStylingMap[normalizedExt as FileExtensionName]
      : genericFile;
  }, [ext]);

  return (
    <Box
      className={classNames('asset-modal-version-thumbnail', { isCurrentVersion })}
      tx={{
        pointerEvents: 'none',
        width: '100%',
        height: THUMBNAIL_HEIGHT,
        borderRadius: 2,
        border: '1px solid black',
        boxSizing: 'border-box',
        overflow: 'hidden',
        color: extStyles.iconColor,
        backgroundColor: extStyles.bgColor,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ['&.isCurrentVersion']: {
          boxShadow: '0px 0px 0px 2px #CECECE',
        },
      }}
    >
      <Box as={extStyles.iconComponent} tx={{ width: 32, color: 'currentColor', display: 'block' }} />
    </Box>
  );
});

VersionFileThumbnail.displayName = 'VersionFileThumbnail';
