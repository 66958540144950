import { Box, Text } from '@air/zephyr';
import { ErrorMessage } from 'formik';

import { DimensionMeasurement } from '../DimensionBar';

export interface DimensionBarErrorProps {
  primaryField: DimensionMeasurement;
  shouldShowPrimaryFieldError: boolean;
}

/**
 * @description This component utilizes Formik's `ErrorMessage` component which only shows an error if the field with
 * the corresponding name has an error _and_ has been touched.  In order to ensure only 1 error message is ever shown,
 * we require the the name of the `primaryField` and use it to evaluate which of the (i.e. primary or secondary) fields'
 * errors should be shown.
 */
export const DimensionBarError = ({ shouldShowPrimaryFieldError, primaryField }: DimensionBarErrorProps) => {
  const primaryFieldIsWidth = primaryField === 'width';
  const secondaryField = primaryFieldIsWidth ? 'height' : 'width';

  const Error = ({ name }: { name: string }) => (
    <Text tx={{ color: 'inherit' }} variant="text-ui-12">
      <ErrorMessage name={name} />
    </Text>
  );

  return (
    <Box
      tx={{
        color: 'flamingo600',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        mt: 4,
      }}
    >
      {shouldShowPrimaryFieldError ? <Error name={primaryField} /> : <Error name={secondaryField} />}
    </Box>
  );
};
