import { AnnotationType, Line } from '@air/api/types';
import { defaultMemoizedContextValue, MemoizedContextType } from '@air/react-memoized-context';
import { noop } from 'lodash';

import { FreeformAnnotation } from '~/components/Annotations/shared/types';

export type AnnotationContextValue = {
  newAnnotation?: FreeformAnnotation;
  activeAnnotation?: FreeformAnnotation;
  annotationType: AnnotationType;
  annotationColor: string;
  annotationSize: number;
  isAnnotationsEnabled: boolean;
  revertedLines: Line[];
};

export interface AnnotationContextType extends MemoizedContextType<AnnotationContextValue> {
  setNewAnnotation: (value?: FreeformAnnotation) => void;
  setActiveAnnotation: (value?: FreeformAnnotation) => void;
  setAnnotationType: (type: AnnotationType) => void;
  setAnnotationColor: (color: string) => void;
  setAnnotationSize: (size: number) => void;
  setAnnotationsEnabled: (enabled: boolean) => void;
  addRevertedLine: (line: Line) => void;
  clearRevertedLines: () => void;
  clearNewAnnotation: () => void;
  undo: () => void;
  redo: () => void;
}

export const defaultValue: AnnotationContextType = {
  ...defaultMemoizedContextValue,
  getValue: () => ({
    annotationType: 'box',
    annotationColor: 'flamingo400',
    annotationSize: 3,
    isAnnotationsEnabled: false,
    revertedLines: [],
  }),
  setNewAnnotation: noop,
  setActiveAnnotation: noop,
  setAnnotationColor: noop,
  setAnnotationType: noop,
  setAnnotationSize: noop,
  redo: noop,
  undo: noop,
  clearNewAnnotation: noop,
  setAnnotationsEnabled: noop,
  addRevertedLine: noop,
  clearRevertedLines: noop,
};

export enum AnnotationProviderActions {
  setNewAnnotation = 'setNewAnnotation',
  setActiveAnnotation = 'setActiveAnnotation',
  setAnnotationType = 'setAnnotationType',
  setAnnotationColor = 'setAnnotationColor',
  setAnnotationSize = 'setAnnotationSize',
  setAnnotationsEnabled = 'setAnnotationsEnabled',
  addRevertedLine = 'addRevertedLine',
  redoLine = 'redoLine',
  clearRevertedLines = 'clearRevertedLines',
  clearNewAnnotation = 'clearNewAnnotation',
}
