import { Tooltip } from '@air/primitive-tooltip';
import { SVGComponent } from '@air/zephyr';
import { memo } from 'react';

interface InfoPanelCameraDetailsItemProps {
  text: string | number;
  Icon: SVGComponent;
  label: string;
}

export const InfoPanelCameraDetailsItem = memo(({ text, Icon, label }: InfoPanelCameraDetailsItemProps) => {
  return (
    <div className="flex gap-1">
      <Tooltip label={label} side="left">
        <Icon className="h-4 w-4 text-grey-8" />
      </Tooltip>
      <p className="text-12 text-grey-12">{text}</p>
    </div>
  );
});

InfoPanelCameraDetailsItem.displayName = 'InfoPanelCameraDetailsItem';
