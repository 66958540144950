import { useVirtualizer } from '@tanstack/react-virtual';
import React, { forwardRef, memo, useEffect, useImperativeHandle, useRef } from 'react';

export interface ClipFacesVirtualizedListProps {
  itemsCount: number;
  hasNextPage: boolean;
  fetchNextPage: () => void;
  isFetchingNextPage: boolean;
  children: (props: { index: number }) => React.ReactNode;
}

export interface ClipFacesVirtualizedListHandle {
  scrollToRow: (index: number) => void;
}

export const FACE_ROW_HEIGHT = 60;

const _ClipFacesVirtualizedList = forwardRef<ClipFacesVirtualizedListHandle, ClipFacesVirtualizedListProps>(
  (
    { itemsCount, hasNextPage, fetchNextPage, isFetchingNextPage, children }: ClipFacesVirtualizedListProps,
    forwardedRef,
  ) => {
    const scrollElementRef = useRef<HTMLDivElement | null>(null);

    const virtualizer = useVirtualizer({
      count: hasNextPage ? itemsCount + 1 : itemsCount,
      estimateSize: () => FACE_ROW_HEIGHT,
      getScrollElement: () => scrollElementRef.current,
      overscan: 10,
      scrollMargin: 16,
    });

    useImperativeHandle(forwardedRef, () => ({
      scrollToRow: (index) => virtualizer.scrollToIndex(index, { behavior: 'smooth' }),
    }));

    const virtualItems = virtualizer.getVirtualItems();

    useEffect(() => {
      const lastItem = virtualItems[virtualItems.length - 1];

      if (!lastItem) {
        return;
      }

      if (lastItem.index >= itemsCount - 1 && hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    }, [hasNextPage, fetchNextPage, isFetchingNextPage, virtualItems, itemsCount]);

    return (
      <div
        data-testid="CLIP_FACES_LIST_SCROLL"
        ref={scrollElementRef}
        className="w-full flex-1 overflow-y-auto pl-4 scrollbar scrollbar-track-grey-2 scrollbar-thumb-grey-7 [&:not(:hover)]:scrollbar-track-transparent"
      >
        <div className="relative w-full" style={{ height: virtualizer.getTotalSize() }}>
          <div
            className="absolute left-0 top-0 w-full pb-4"
            style={{ transform: `translateY(${(virtualItems[0]?.start ?? 0) + virtualizer.options.scrollMargin}px)` }}
          >
            {virtualItems.map((virtualRow) => (
              <div key={virtualRow.key} data-index={virtualRow.index} ref={virtualizer.measureElement}>
                {children({ index: virtualRow.index })}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  },
);

_ClipFacesVirtualizedList.displayName = '_ClipFacesVirtualizedList';

export const ClipFacesVirtualizedList = memo(_ClipFacesVirtualizedList);

ClipFacesVirtualizedList.displayName = 'ClipFacesVirtualizedList';
