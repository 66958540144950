import { FormikSingleSelect } from '~/components/Zephyr/Formik/FormikSingleSelect';
import { IMAGE_FORMAT_SINGLE_SELECT } from '~/constants/testIDs';
import { SUPPORTED_EXPORT_FORMATS } from '~/utils/Imgix';

const extensionOptions = SUPPORTED_EXPORT_FORMATS.map((format) => ({
  label: format.toUpperCase(),
  value: format,
}));

export interface ExportImageExtSelectProps {
  setIsEditing: (isEditing: boolean) => void;
}

export const ExportImageExtSelect = ({ setIsEditing }: ExportImageExtSelectProps) => {
  return (
    <FormikSingleSelect
      isSearchable={false}
      required={false}
      variant="field-input-chonky"
      data-testid={IMAGE_FORMAT_SINGLE_SELECT}
      label="Format"
      name="ext"
      options={extensionOptions}
      onOptionsClose={() => {
        setIsEditing(false);
      }}
      onOptionsOpen={() => {
        setIsEditing(true);
      }}
      spacingStyles={{ minWidth: 157 }}
    />
  );
};
