import { Annotation, Clip, CommentResponse } from '@air/api/types';
import { EllipsisVertical, Resolve } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { Discussion as DiscussionType } from '@air/types/dist/discussion';
import { Box } from '@air/zephyr';
import { memo, useCallback, useMemo } from 'react';

import { ClipDiscussion } from '~/components/Annotations/shared/types';
import { DropdownMenuWithActionSheet } from '~/components/Menus/DropdownMenuWithActionSheet';
import { QueryParamNames } from '~/constants/search';
import { DISCUSSION_COMMENT_MENU_TRIGGER, RESOLVE_DISCUSSION_BUTTON } from '~/constants/testIDs';
import { activeDiscussionIdParamSelector } from '~/store/router/selectors';
import { getDiscussionCommentMenuOptions } from '~/utils/menuOptions/getDiscussionCommentMenuOptions';
import { setQueryParams } from '~/utils/PathUtils';
import { useAirStore } from '~/utils/ReduxUtils';

export interface CommentMenuProps {
  onEdit?: () => void;

  onToggleResolve:
    | ((params: {
        id: DiscussionType['id'];
        resolved: DiscussionType['resolved'];
        clipId: Clip['id'];
        timestamp?: number;
        annotation?: Annotation;
      }) => void)
    | undefined;

  onDelete?: (params: {
    comment: CommentResponse;
    clipId: Clip['id'];
    timestamp?: number;
    annotation?: Annotation;
  }) => void;

  discussion: ClipDiscussion;
  comment: CommentResponse;
}

export const CommentMenu = memo(({ onToggleResolve, onEdit, onDelete, discussion, comment }: CommentMenuProps) => {
  const store = useAirStore();

  const handleDeleteClick = useCallback(() => {
    onDelete?.({
      comment,
      clipId: discussion.clipId,
      timestamp: discussion.timestamp,
      annotation: discussion.annotation,
    });
    const activeDiscussionId = activeDiscussionIdParamSelector(store.getState());
    if (activeDiscussionId === discussion.id) {
      setQueryParams({ [QueryParamNames.discussionId]: undefined }, 'replace');
    }
  }, [comment, discussion, onDelete, store]);

  const handleToggleResolveClick = useCallback(() => {
    onToggleResolve?.({
      clipId: discussion.clipId,
      timestamp: discussion.timestamp,
      annotation: discussion.annotation,
      id: discussion.id,
      resolved: !discussion.resolved,
    });
  }, [discussion, onToggleResolve]);

  const menuOptions = useMemo(
    () =>
      getDiscussionCommentMenuOptions({
        onDelete: onDelete ? handleDeleteClick : undefined,
        onEdit,
        onResolve: onToggleResolve ? handleToggleResolveClick : undefined,
      }),
    [handleDeleteClick, handleToggleResolveClick, onDelete, onEdit, onToggleResolve],
  );

  if (!menuOptions.length && !onToggleResolve) {
    return null;
  }

  const hasOnlyResolveInMenu = menuOptions.length === 1 && menuOptions[0].id === 'resolve';

  return (
    <Box
      className="comment-menu"
      tx={{ display: 'flex', alignItems: 'center' }}
      onClick={(event) => event.stopPropagation()}
    >
      {onToggleResolve && (
        <IconButton
          appearance="ghost"
          color="grey"
          data-testid={RESOLVE_DISCUSSION_BUTTON}
          icon={Resolve}
          label="Resolve discussion"
          onClick={handleToggleResolveClick}
          size="small"
        />
      )}
      {menuOptions.length > 0 && !hasOnlyResolveInMenu && (
        <DropdownMenuWithActionSheet
          isTitleHidden
          options={menuOptions}
          title="More comment options"
          trigger={
            <IconButton
              appearance="ghost"
              color="grey"
              data-testid={DISCUSSION_COMMENT_MENU_TRIGGER}
              icon={EllipsisVertical}
              label="More options"
              size="small"
            />
          }
        />
      )}
    </Box>
  );
});

CommentMenu.displayName = 'CommentMenu';
