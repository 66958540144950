import { Close, FullScreenEnter, FullScreenOut, Images, ZoomIn, ZoomOut } from '@air/next-icons';
import { memo } from 'react';

import { KeyShortcut } from '~/components/AssetModal/Panel/Tabs/KeyboardShortcutsInfoButton/KeyboardShortcutsInfoModal/shared/KeyShortcut';
import { CmdShortcut, LeftArrowShortcut, RightArrowShortcut, ShiftShortcut } from '~/components/KeyboardShortcuts';
import { ShortcutInfo } from '~/components/ShortcutInfo';

export const BasicShortcutsInfo = memo(() => (
  <>
    <KeyShortcut icon={Images} text="Next/previous asset">
      <LeftArrowShortcut />
      <span className="mx-0.5 text-12 text-grey-10">/</span>
      <RightArrowShortcut />
    </KeyShortcut>
    <KeyShortcut icon={ZoomIn} text="Zoom in">
      <CmdShortcut />
      <ShortcutInfo>+</ShortcutInfo>
    </KeyShortcut>
    <KeyShortcut icon={ZoomOut} text="Zoom out">
      <CmdShortcut />
      <ShortcutInfo>-</ShortcutInfo>
    </KeyShortcut>
    <KeyShortcut icon={FullScreenOut} text="Zoom to 100%">
      <CmdShortcut />
      <ShiftShortcut />
      <ShortcutInfo>0</ShortcutInfo>
    </KeyShortcut>
    <KeyShortcut icon={FullScreenEnter} text="Full screen">
      <CmdShortcut />
      <ShortcutInfo>0</ShortcutInfo>
    </KeyShortcut>
    <KeyShortcut icon={Close} text="Close asset">
      <ShortcutInfo>ESC</ShortcutInfo>
    </KeyShortcut>
  </>
));

BasicShortcutsInfo.displayName = 'BasicShortcutsInfo';
