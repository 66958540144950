import { AnnotationOutlinePlus, Comment, LineweightMedium, Redo, Undo } from '@air/next-icons';
import { memo } from 'react';

import { KeyShortcut } from '~/components/AssetModal/Panel/Tabs/KeyboardShortcutsInfoButton/KeyboardShortcutsInfoModal/shared/KeyShortcut';
import { CmdShortcut, ShiftShortcut } from '~/components/KeyboardShortcuts';
import { ShortcutInfo } from '~/components/ShortcutInfo';

export const AnnotationsShortcutsInfo = memo(() => {
  return (
    <>
      <KeyShortcut icon={Comment} text="Open/close comments panel">
        <CmdShortcut />
        <ShiftShortcut />
        <ShortcutInfo>C</ShortcutInfo>
      </KeyShortcut>
      <KeyShortcut icon={AnnotationOutlinePlus} text="Add annotation">
        <CmdShortcut />
        <ShiftShortcut />
        <ShortcutInfo>A</ShortcutInfo>
      </KeyShortcut>
      <KeyShortcut icon={LineweightMedium} text="Draw annotation">
        <CmdShortcut />
        <ShiftShortcut />
        <ShortcutInfo>D</ShortcutInfo>
      </KeyShortcut>
      <KeyShortcut icon={Undo} text="Undo">
        <CmdShortcut />
        <ShortcutInfo>Z</ShortcutInfo>
      </KeyShortcut>
      <KeyShortcut icon={Redo} text="Redo">
        <CmdShortcut />
        <ShiftShortcut />
        <ShortcutInfo>Z</ShortcutInfo>
      </KeyShortcut>
    </>
  );
});

AnnotationsShortcutsInfo.displayName = 'AnnotationsShortcutsInfo';
