import { Board } from '@air/api/types';
import { Breadcrumbs, BreadcrumbsItem } from '@air/component-breadcrumbs';
import { tailwindMerge } from '@air/tailwind-variants';
import { Box, Text } from '@air/zephyr';
import { memo, useMemo } from 'react';

export interface BoardPathItemProps {
  board: Pick<Board, 'title' | 'id'>;
  showDivider?: boolean;
  size: 'regular' | 'small';
}

type Sizes = 'text-ui-12' | 'text-ui-14';

const sizeMap: { [key in BoardPathItemProps['size']]: Sizes } = {
  regular: 'text-ui-14',
  small: 'text-ui-12',
};

const Divider = memo(() => <div className="mx-1 text-12 text-pigeon-500">/</div>);

Divider.displayName = 'Divider';

const BoardPathItem = memo(({ board: { id, title }, showDivider = true, size }: BoardPathItemProps) => (
  <Box tx={{ display: 'flex', alignItems: 'center', overflow: 'hidden', maxWidth: [230, 500, 500, 500, 230] }} key={id}>
    <Text
      variant={[sizeMap[size]]}
      tx={{
        color: 'inherit',
        lineHeight: 1.5,
        display: 'block',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: [230, 500, 500, 500, 230],
      }}
    >
      {title}
    </Text>

    {showDivider && <Divider />}
  </Box>
));

BoardPathItem.displayName = 'BoardPathItem';

export interface BoardAncestorPathProps {
  ancestors: Pick<Board, 'title' | 'id'>[];
  className?: string;
}

export const BoardAncestorPath = memo(({ ancestors, className }: BoardAncestorPathProps) => {
  const ancestorsItems = useMemo<BreadcrumbsItem[]>(() => {
    const items: BreadcrumbsItem[] = [];
    const boardAncestors = ancestors ?? [];

    items.push(
      ...boardAncestors.map<BreadcrumbsItem>((ancestor, index) => ({
        className: tailwindMerge('truncate font-normal text-inherit', index === 0 ? 'pl-0' : ''),
        id: ancestor.id,
        label: ancestor.title,
        type: 'item',
      })),
    );

    return items;
  }, [ancestors]);

  if (ancestors.length === 0) {
    return null;
  }

  return (
    <Breadcrumbs
      isCompact
      className={tailwindMerge('overflow-visible text-12 text-grey-10', className)}
      items={ancestorsItems}
    />
  );
});

BoardAncestorPath.displayName = 'BoardAncestorPath';
