import { FormikSingleSelect } from '~/components/Zephyr/Formik/FormikSingleSelect';
import { IMAGE_SIZE_SINGLE_SELECT } from '~/constants/testIDs';

import { defaultImageSizeOptions } from '../../../ImageFormatFormField';

export interface ExportImageSizeSelectProps {
  setIsEditing: (isFocused: boolean) => void;
}

export const ExportImageSizeSelect = ({ setIsEditing }: ExportImageSizeSelectProps) => {
  return (
    <FormikSingleSelect
      isSearchable={false}
      required={false}
      variant="field-input-chonky"
      data-testid={IMAGE_SIZE_SINGLE_SELECT}
      label="Size"
      name="size"
      options={defaultImageSizeOptions}
      onOptionsClose={() => {
        setIsEditing(false);
      }}
      onOptionsOpen={() => {
        setIsEditing(true);
      }}
    />
  );
};
