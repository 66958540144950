import classNames from 'classnames';
import { memo } from 'react';

import { ASSET_MODAL_HEADER_IN_INFO } from '~/constants/testIDs';

interface AssetLocationNameProps {
  onClick?: () => void;
  location: string;
}

export const AssetLocationName = memo(({ onClick, location }: AssetLocationNameProps) => {
  return (
    <p className="truncate text-14 font-medium text-grey-11" data-testid={ASSET_MODAL_HEADER_IN_INFO}>
      in
      <span
        className={classNames('pl-1 font-medium', !!onClick ? 'cursor-pointer hover:underline' : '')}
        onClick={onClick}
      >
        {location}
      </span>
    </p>
  );
});

AssetLocationName.displayName = 'AssetLocationName';
