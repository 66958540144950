import { Loop as LoopIcon } from '@air/next-icons';
import { memo } from 'react';

import { DarkIconButton } from '~/components/AssetModal/shared/components/DarkIconButton';

interface LoopVideoButtonProps {
  shouldLoopVideo: boolean;
  toggleLoopVideo: () => void;
}
export const LoopVideoButton = memo(({ shouldLoopVideo, toggleLoopVideo }: LoopVideoButtonProps) => {
  return (
    <DarkIconButton
      icon={LoopIcon}
      onClick={toggleLoopVideo}
      tx={{
        color: shouldLoopVideo ? 'peacock500' : 'pigeon100',
        mr: [0, 8],
        ['&:hover']: {
          backgroundColor: 'pigeon700',
          color: shouldLoopVideo ? 'peacock500' : 'pigeon050',
        },
      }}
    >
      {shouldLoopVideo ? 'unloop' : 'loop'}
    </DarkIconButton>
  );
});

LoopVideoButton.displayName = 'LoopVideoButton';
