import { DiscussionsComment } from '@air/component-discussions';
import { Close } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { formatFullName, formatUpdatedAt } from '@air/utilities';
import { useCurrentUserSession } from '@air/utils-auth/src/hooks/useCurrentUserSession';
import { Box } from '@air/zephyr';
import { memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { AnnotationReplyButton } from '~/components/Annotations/AnnotationsAvatarsContainer/AnnotationAvatarContainer/components/AnnotationReplyButton';
import { ClipDiscussion } from '~/components/Annotations/shared/types';
import { useDiscussionPanelContextSelector } from '~/components/AssetModal/shared/context/DiscussionsPanelContext/DiscussionsPanelContext';
import { DiscussionsPanelContextValue } from '~/components/AssetModal/shared/context/DiscussionsPanelContext/discussionsPanelContextTypes';
import { BOUNDING_BOX_AVATAR_SIZE } from '~/components/BoundingBox/components/BoundingBoxAvatar';
import { BOUNDING_BOX_TEXT_WIDTH } from '~/components/BoundingBox/constants';
import { BoundingBoxAvatarDirection } from '~/components/BoundingBox/types';
import { CommentMenu } from '~/components/Discussions/Discussion/DiscussionComment/components/CommentMenu';
import { DiscussionCommentContent } from '~/components/Discussions/Discussion/DiscussionComment/components/DiscussionCommentContent';
import { ReopenDiscussionButton } from '~/components/Discussions/Discussion/DiscussionComment/components/ReopenDiscussionButton';
import { useCommentEditing } from '~/components/Discussions/Discussion/shared/useCommentEditing';
import { QueryParamNames } from '~/constants/search';
import { ANNOTATION_COMMENT_CONTAINER, CLOSE_FLOATING_COMMENT_BUTTON } from '~/constants/testIDs';
import { useURLBoardIdSelector } from '~/hooks/useURLBoardIdSelector';
import { useAccountContext } from '~/providers/AccountProvider';
import { centralizedBoardAncestorsSelector } from '~/store/centralizedBoard/selectors';
import { useDeleteCommentClick } from '~/swr-hooks/discussions/useDeleteCommentClick';
import { useGetDiscussionMentionsMembers } from '~/swr-hooks/discussions/useGetDiscussionMentionsMembers';
import { useResolveCommentClick } from '~/swr-hooks/discussions/useResolveCommentClick';
import { useSaveEditedCommentClick } from '~/swr-hooks/discussions/useSaveCommentEditClick';
import { isCommentAuthor } from '~/utils/comments';
import { parseAirDateToISO } from '~/utils/DateUtils';
import { setQueryParams } from '~/utils/PathUtils';

export interface AnnotationAvatarWithCommentProps {
  discussion: ClipDiscussion;
  position: BoundingBoxAvatarDirection;
  canEdit: boolean;
  canDelete: boolean;
  canResolve: boolean;
  canShowMentionsButton: boolean;
  canReply: boolean;
}

export const ANNOTATION_COMMENT_ID = 'annotation-comment';

export const AnnotationComment = memo(
  ({
    discussion,
    position,
    canEdit,
    canResolve,
    canDelete,
    canReply,
    canShowMentionsButton,
  }: AnnotationAvatarWithCommentProps) => {
    const boardId = useURLBoardIdSelector();
    const ancestors = useSelector(centralizedBoardAncestorsSelector);
    const firstComment = discussion.comments.data[0];
    const { data: account } = useAccountContext();
    const { data: userSession } = useCurrentUserSession();
    const isAuthor = isCommentAuthor({ comment: firstComment, account, userSession });

    const { enableEditing, isEditing, disableEditing } = useCommentEditing();

    const { handleResolveCommentClick } = useResolveCommentClick({ trackLocation: 'asset-page' });
    const { handleCommentDeleteClick } = useDeleteCommentClick({ trackLocation: 'asset-page' });
    const { handleSaveEditedCommentClick } = useSaveEditedCommentClick({ trackLocation: 'asset-page' });

    const isReplyingCurrentDiscussionIdSelector = useCallback(
      (state: DiscussionsPanelContextValue) => state.replyingDiscussionId === discussion.id,
      [discussion.id],
    );

    const isAddingReply = useDiscussionPanelContextSelector(isReplyingCurrentDiscussionIdSelector);

    const mentionsList = useGetDiscussionMentionsMembers({ boardId, ancestors, workspaceId: discussion.workspaceId });

    const positionValue = BOUNDING_BOX_AVATAR_SIZE + 12;
    const horizontalPos = useMemo(
      () => (position.includes('left') ? { right: positionValue } : { left: positionValue }),
      [position, positionValue],
    );

    const onSaveClick = useCallback(
      (newComment: string) => {
        handleSaveEditedCommentClick({
          newComment,
          comment: firstComment,
          clipId: discussion.clipId,
          timestamp: discussion.timestamp,
          annotation: discussion.annotation,
        });
      },
      [handleSaveEditedCommentClick, firstComment, discussion],
    );

    const commentMenu = useMemo(
      () =>
        discussion.resolved && canResolve ? (
          <ReopenDiscussionButton onToggleResolve={handleResolveCommentClick} discussion={discussion} />
        ) : (
          <div className="flex items-center">
            <CommentMenu
              comment={firstComment}
              discussion={discussion}
              onToggleResolve={canResolve ? handleResolveCommentClick : undefined}
              onDelete={canDelete && isAuthor ? handleCommentDeleteClick : undefined}
              onEdit={canEdit && isAuthor ? enableEditing : undefined}
            />
            <IconButton
              data-testid={CLOSE_FLOATING_COMMENT_BUTTON}
              icon={Close}
              appearance="ghost"
              color="grey"
              size="small"
              onClick={() => setQueryParams({ [QueryParamNames.discussionId]: null }, 'replace')}
              label="Close annotation"
            />
          </div>
        ),
      [
        discussion,
        canResolve,
        handleResolveCommentClick,
        firstComment,
        canDelete,
        isAuthor,
        handleCommentDeleteClick,
        canEdit,
        enableEditing,
      ],
    );

    if (!firstComment) return null;

    return (
      <Box
        className="rounded-lg bg-grey-1 shadow-popover"
        id={ANNOTATION_COMMENT_ID}
        data-testid={ANNOTATION_COMMENT_CONTAINER}
        tx={{
          width: BOUNDING_BOX_TEXT_WIDTH,

          '.discussion-form-container': { mr: 0 },
        }}
        style={horizontalPos}
      >
        <DiscussionsComment
          actions={commentMenu}
          author={formatFullName(firstComment.account.firstName, firstComment.account.lastName)}
          comment={
            <DiscussionCommentContent
              isEditing={isEditing}
              cancelEditing={disableEditing}
              comment={firstComment}
              onSave={canEdit ? onSaveClick : undefined}
              mentionsList={mentionsList}
              shouldShowMentionsButton={canShowMentionsButton}
            />
          }
          data-testid="ANNOTATION_COMMENT"
          timestamp={formatUpdatedAt(parseAirDateToISO(firstComment.createdAt).getTime())}
          secondaryActions={
            canReply && !discussion.resolved && !isAddingReply && <AnnotationReplyButton discussion={discussion} />
          }
        />
      </Box>
    );
  },
);

AnnotationComment.displayName = 'AnnotationComment';
