import { Modal } from '@air/zephyr';
import { memo } from 'react';

import AirTabs from '~/components/AirTabs';
import { AnnotationsShortcutsInfo } from '~/components/AssetModal/Panel/Tabs/KeyboardShortcutsInfoButton/KeyboardShortcutsInfoModal/components/AnnotationsShortcutsInfo';
import { BasicShortcutsInfo } from '~/components/AssetModal/Panel/Tabs/KeyboardShortcutsInfoButton/KeyboardShortcutsInfoModal/components/BasicShortcutsInfo';
import { VideoShortcutsInfo } from '~/components/AssetModal/Panel/Tabs/KeyboardShortcutsInfoButton/KeyboardShortcutsInfoModal/components/VideoShortcutsInfo';
import { KEYBOARD_SHORTCUTS_MODAL } from '~/constants/testIDs';

const tabsTitles = ['General', 'Comments', 'Video'];

export const KeyboardShortcutsInfoModal = memo(({ onClose }: AirModalProps) => {
  return (
    <Modal
      data-testid={KEYBOARD_SHORTCUTS_MODAL}
      onDismiss={onClose}
      isAlertModal={false}
      modalLabel="Keyboard Shortcuts"
    >
      <AirTabs tabsTitles={tabsTitles} style={{ marginBottom: 0 }}>
        <div className="pt-4">
          <BasicShortcutsInfo />
        </div>
        <div className="pt-4">
          <AnnotationsShortcutsInfo />
        </div>
        <div className="pt-4">
          <VideoShortcutsInfo />
        </div>
      </AirTabs>
    </Modal>
  );
});

KeyboardShortcutsInfoModal.displayName = 'KeyboardShortcutsInfoModal';
