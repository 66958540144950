import { Clock } from '@air/next-icons';
import { format } from 'date-fns';
import { memo } from 'react';
import { useSelector } from 'react-redux';

import { InfoPanelDetailsSection } from '~/components/AssetModal/shared/components/InfoPanel/components/shared/InfoPanelDetailsSection';
import { InfoPanelDetailValue } from '~/components/AssetModal/shared/components/InfoPanel/components/shared/InfoPanelDetailValue';
import { centralizedClipUpdatedAtSelector } from '~/store/centralizedClip/selectors';
import { MONTH_DAY_YEAR_FORMAT, parseAirDateToISO } from '~/utils/DateUtils';

export const InfoPanelDateModified = memo(() => {
  const updatedAt = useSelector(centralizedClipUpdatedAtSelector);
  const formattedUpdatedAt = format(parseAirDateToISO(updatedAt), MONTH_DAY_YEAR_FORMAT);

  return (
    <InfoPanelDetailsSection Icon={Clock} title="Last Modified">
      <InfoPanelDetailValue value={formattedUpdatedAt} additionalInfo="Last modified" />
    </InfoPanelDetailsSection>
  );
});

InfoPanelDateModified.displayName = 'InfoPanelDateModified';
