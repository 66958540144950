import { FullScreenEnter as FullScreenEnterIcon, FullScreenOut as FullScreenOutIcon } from '@air/next-icons';
import { memo } from 'react';

import { DarkIconButton } from '~/components/AssetModal/shared/components/DarkIconButton';

interface FullScreenButtonProps {
  onFullScreenToggle: () => void;
  isFullScreen: boolean;
  iconText: string;
}

export const FullScreenButton = memo(({ onFullScreenToggle, isFullScreen, iconText }: FullScreenButtonProps) => {
  return (
    <DarkIconButton
      onClick={onFullScreenToggle}
      icon={isFullScreen ? FullScreenOutIcon : FullScreenEnterIcon}
      tx={{
        ml: [0, 8],
      }}
    >
      {iconText}
    </DarkIconButton>
  );
});

FullScreenButton.displayName = 'FullScreenButton';
