import { AnnotationType } from '@air/api/types';
import { CSSProperties } from 'react';

import { COMMENT_CURSOR } from '~/constants/cursors';

export const annotationTooltipText: { [key in AnnotationType]: string } = {
  box: 'Click to comment on a point or drag to select an area',
  pencil: 'Draw on area to add a comment',
};

export const annotationTooltipCursor: { [key in AnnotationType]: CSSProperties['cursor'] } = {
  box: COMMENT_CURSOR,
  pencil: 'url(/assets/cursors/pencilAnnotation.svg) 0 18,auto',
};
