import { useTrackAnnotationSizeChanged } from '@air/analytics';
import { IconButton } from '@air/zephyr';
import { rgba } from 'polished';
import { memo } from 'react';
import { useTheme } from 'styled-components';

import { annotationSizes } from '~/components/AssetModal/AssetModalFooter/AnnotationsToggle/AnnotateUtilsPanel/shared/constants';
import { ANNOTATION_SIZE_SELECT } from '~/constants/testIDs';

export interface AnnotationSizeSelectProps {
  selectedSize: number;
  setSize: (size: number) => void;
}

export const AnnotationSizeSelect = memo(({ selectedSize, setSize }: AnnotationSizeSelectProps) => {
  const theme = useTheme();
  const { trackAnnotationSizeChanged } = useTrackAnnotationSizeChanged();

  return (
    <div className="flex py-2">
      {annotationSizes.map((size) => (
        <IconButton
          data-testid={`${ANNOTATION_SIZE_SELECT}-${size.width}`}
          variant={selectedSize === size.width ? 'button-filled-grey' : 'button-ghost-grey'}
          key={size.width}
          size="extra-small"
          icon={size.icon}
          onClick={(event: MouseEvent) => {
            event.stopPropagation();
            trackAnnotationSizeChanged({ size: size.width });
            setSize(size.width);
          }}
          tx={{
            width: 24,
            height: 24,
            mx: 4,
            ...(selectedSize === size.width ? { color: 'pigeon500', bg: rgba(theme.colors.pigeon800, 0.15) } : {}),
            svg: {
              width: 20,
            },
          }}
        >
          {`Change size to ${size}`}
        </IconButton>
      ))}
    </div>
  );
});

AnnotationSizeSelect.displayName = 'AnnotationSizeSelect';
