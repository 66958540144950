import { Clock as ClockIcon } from '@air/next-icons';
import { Tooltip } from '@air/primitive-tooltip';
import { Button } from '@air/zephyr';
import classNames from 'classnames';
import React from 'react';

import { MENTIONS_TIMESTAMP_PILL, TIMESTAMP_INPUT_BUTTON } from '~/constants/testIDs';

interface TimestampButtonProps {
  shouldEnableTimestamps: boolean;
  setShouldEnableTimestamps: (shouldEnableTimestamps: boolean) => void;
  formattedTimestamp?: string;
  shouldDisableTimestampsAndAnnotations: boolean;
}

export const TimestampButton = ({
  shouldEnableTimestamps,
  setShouldEnableTimestamps,
  formattedTimestamp,
  shouldDisableTimestampsAndAnnotations,
}: TimestampButtonProps) => {
  return (
    <Tooltip
      label={
        shouldDisableTimestampsAndAnnotations ? 'Timestamp commenting is disabled during video processing' : 'Timestamp'
      }
      side="top"
    >
      <Button
        avoidCollisions
        disabled={shouldDisableTimestampsAndAnnotations}
        variant="button-unstyled"
        tx={{
          p: shouldEnableTimestamps ? 4 : 0,
          backgroundColor: shouldEnableTimestamps ? 'jay020' : 'transparent',
          borderRadius: 40,
          '&:hover': {
            backgroundColor: shouldEnableTimestamps ? 'jay050' : 'transparent',
          },
        }}
        onClick={() => setShouldEnableTimestamps(!shouldEnableTimestamps)}
      >
        <ClockIcon
          aria-label="Timestamp"
          className={classNames(
            'rounded-full hover:text-jay-600',
            shouldEnableTimestamps ? 'w-4 p-0 hover:bg-transparent' : 'w-6 p-1 hover:bg-jay-50',
            shouldEnableTimestamps
              ? 'text-jay-600'
              : shouldDisableTimestampsAndAnnotations
              ? 'text-grey-8'
              : 'text-grey-11',
          )}
          data-testid={TIMESTAMP_INPUT_BUTTON}
        />
        {shouldEnableTimestamps && (
          <div className="ml-1 mr-0.5 text-10 font-medium text-jay-600" data-testid={MENTIONS_TIMESTAMP_PILL}>
            {formattedTimestamp}
          </div>
        )}
      </Button>
    </Tooltip>
  );
};
