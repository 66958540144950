import { ClipMetadata } from '@air/api';
import { Aperture, Calendar, Camera, FocalLength, Iso, Timer } from '@air/next-icons';
import { memo } from 'react';

import { InfoPanelCameraDetailsItem } from '~/components/AssetModal/shared/components/InfoPanel/components/InfoPanelCameraDetails/components/InfoPanelCameraDetailsItem';
import { getCameraSectionTitle } from '~/components/AssetModal/shared/components/InfoPanel/components/InfoPanelCameraDetails/utils/getCameraSectionTitle';
import { InfoPanelDetailsSection } from '~/components/AssetModal/shared/components/InfoPanel/components/shared/InfoPanelDetailsSection';
import { InfoPanelDetailTitle } from '~/components/AssetModal/shared/components/InfoPanel/components/shared/IntoPanelDetailTitle';
import { MONTH_DAY_YEAR_FORMAT } from '~/utils/DateUtils';
import { formatMetadataDate, isNumberMetadataType, isStringMetadataType } from '~/utils/iptcDataTypes';

interface InfoPanelCameraDetailsProps {
  clipMetadata: ClipMetadata[];
}

export const InfoPanelCameraDetails = memo(({ clipMetadata }: InfoPanelCameraDetailsProps) => {
  const cameraMake = clipMetadata.find((metadata) => metadata.fieldName === 'Make');
  const cameraModel = clipMetadata.find((metadata) => metadata.fieldName === 'Model');
  const iso = clipMetadata.find((metadata) => metadata.fieldName === 'ISO');
  const aperture = clipMetadata.find((metadata) => metadata.fieldName === 'FNumber');
  const exposureTime = clipMetadata.find((metadata) => metadata.fieldName === 'ExposureTime');
  const focalLength = clipMetadata.find((metadata) => metadata.fieldName === 'FocalLength');
  const dateTaken = clipMetadata.find((metadata) => metadata.fieldName === 'DateTimeOriginal');

  if (
    !cameraMake?.data &&
    !cameraModel?.data &&
    !iso?.data &&
    !aperture?.data &&
    !exposureTime?.data &&
    !focalLength?.data &&
    !dateTaken?.data
  ) {
    return null;
  }

  const title = getCameraSectionTitle({ makeMetadata: cameraMake, modelMetadata: cameraModel });

  const formattedDateTaken = dateTaken?.data ? formatMetadataDate(dateTaken, MONTH_DAY_YEAR_FORMAT) : null;

  return (
    <InfoPanelDetailsSection Icon={Camera} title="Camera">
      <InfoPanelDetailTitle>{title}</InfoPanelDetailTitle>
      <div className="mt-1 flex flex-wrap [&>div]:mb-1 [&>div]:basis-1/2">
        {!!iso?.data && isNumberMetadataType(iso) && (
          <InfoPanelCameraDetailsItem text={iso.data} Icon={Iso} label="ISO" />
        )}
        {!!aperture?.data && isNumberMetadataType(aperture) && (
          <InfoPanelCameraDetailsItem text={`ƒ/${aperture.data}`} Icon={Aperture} label="Aperture" />
        )}
        {!!exposureTime?.data && isStringMetadataType(exposureTime) && (
          <InfoPanelCameraDetailsItem text={exposureTime.data} Icon={Timer} label="Exposure Time" />
        )}
        {!!focalLength?.data && isStringMetadataType(focalLength) && (
          <InfoPanelCameraDetailsItem text={focalLength.data} Icon={FocalLength} label="Focal Length" />
        )}
      </div>
      {!!formattedDateTaken && (
        <InfoPanelCameraDetailsItem text={formattedDateTaken} Icon={Calendar} label="Date Taken" />
      )}
    </InfoPanelDetailsSection>
  );
});

InfoPanelCameraDetails.displayName = 'InfoPanelCameraDetails';
