import { Calendar } from '@air/next-icons';
import { format } from 'date-fns';
import { memo } from 'react';
import { useSelector } from 'react-redux';

import { InfoPanelDetailsSection } from '~/components/AssetModal/shared/components/InfoPanel/components/shared/InfoPanelDetailsSection';
import { InfoPanelDetailValue } from '~/components/AssetModal/shared/components/InfoPanel/components/shared/InfoPanelDetailValue';
import { centralizedClipRecordedAtSelector } from '~/store/centralizedClip/selectors';
import { MONTH_DAY_YEAR_FORMAT, parseAirDateToISO } from '~/utils/DateUtils';

export const InfoPanelDateCreated = memo(() => {
  const recordedAt = useSelector(centralizedClipRecordedAtSelector);

  const formattedCreatedAt = format(parseAirDateToISO(recordedAt), MONTH_DAY_YEAR_FORMAT);

  if (!formattedCreatedAt) {
    return null;
  }

  return (
    <InfoPanelDetailsSection Icon={Calendar} title="Date Created">
      <InfoPanelDetailValue value={formattedCreatedAt} />
    </InfoPanelDetailsSection>
  );
});

InfoPanelDateCreated.displayName = 'InfoPanelDateCreated';
