import { Button } from '@air/primitive-button';
import { DropdownMenuOption } from '@air/primitive-dropdown-menu';
import { memo, useMemo } from 'react';

import { SelectedOptionIcon } from '~/components/AssetModal/Visualizer/VideoVisualizer/VideoVisualizerInner/shared/SelectedOptionIcon';
import {
  DropdownMenuActionSheetProps,
  DropdownMenuWithActionSheet,
} from '~/components/Menus/DropdownMenuWithActionSheet';

interface PlaybackSpeedOptionsProps {
  currentPlaybackSpeed: number;
  onSelectPlaybackSpeed: (speed: number) => void;
  container?: DropdownMenuActionSheetProps['container'];
}

const playbackSpeeds = [0.5, 0.75, 1, 1.5, 1.75, 2];

export const PlaybackSpeedOptions = memo(
  ({ currentPlaybackSpeed, onSelectPlaybackSpeed, container }: PlaybackSpeedOptionsProps) => {
    const options = useMemo(() => {
      const optionsArray: DropdownMenuOption[] = [
        {
          id: 'speed',
          label: 'Speed',
          type: 'title',
        },
      ];

      playbackSpeeds.map((speed) => {
        optionsArray.push({
          id: `playback-speed-${speed}`,
          label: `${speed}x`,
          onSelect: () => onSelectPlaybackSpeed(speed),
          suffix: speed === currentPlaybackSpeed ? <SelectedOptionIcon /> : null,
          type: 'item',
        });
      });

      return optionsArray;
    }, [currentPlaybackSpeed, onSelectPlaybackSpeed]);

    return (
      <DropdownMenuWithActionSheet
        data-theme="dark"
        options={options}
        container={container}
        trigger={
          <Button
            appearance="ghost"
            className="text-pigeon-100 hover:bg-pigeon-700 hover:text-pigeon-50 active:bg-pigeon-700 active:text-pigeon-50"
            size="large"
          >
            {currentPlaybackSpeed}x
          </Button>
        }
        title="Playback speed"
      />
    );
  },
);

PlaybackSpeedOptions.displayName = 'PlaybackSpeedOptions';
