import { ClipType } from '@air/api/types';
import { Box } from '@air/zephyr';
import { memo, useCallback, useMemo, useState } from 'react';
import { buildURL } from 'react-imgix';
import { useSelector } from 'react-redux';

import { getCloudFrontUrlFromImgixUrl } from '~/constants/cloudfront';
import { IMAGE_THEATER } from '~/constants/testIDs';
import {
  centralizedClipAssetsSelector,
  centralizedClipDescriptionSelector,
  centralizedClipTypeSelector,
} from '~/store/centralizedClip/selectors';

const DEFAULT_IMG_SIZE = 800;

export const MobileImageVisualizer = memo(() => {
  const clipAssets = useSelector(centralizedClipAssetsSelector);
  const description = useSelector(centralizedClipDescriptionSelector);
  const clipType = useSelector(centralizedClipTypeSelector);
  const [error, setError] = useState(false);

  const { src, fallbackUrl } = useMemo(() => {
    const _src = clipType === ClipType.animated && clipAssets.video ? clipAssets.video : clipAssets.image;
    const _fallbackUrl = getCloudFrontUrlFromImgixUrl(_src);

    return {
      src: _src,
      fallbackUrl: _fallbackUrl,
    };
  }, [clipAssets, clipType]);

  const imgxUrl = useMemo(() => {
    return buildURL(
      src,
      {
        w: DEFAULT_IMG_SIZE,
        h: DEFAULT_IMG_SIZE,
        auto: 'compress',
      },
      { src, disableSrcSet: true },
    );
  }, [src]);

  const onError = useCallback(() => {
    setError(true);
  }, []);

  return (
    <Box
      tx={{
        width: '100%',
        height: '100%',
        maxHeight: 'calc(100vh - 238px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '> img': {
          display: 'flex',
          objectFit: 'contain',
          maxWidth: '100%',
          maxHeight: '100%',
          transformOrigin: 'center',
        },
      }}
      data-testid={IMAGE_THEATER}
    >
      {!!error ? (
        // eslint-disable-next-line @next/next/no-img-element
        <img src={fallbackUrl} alt={description || ''} />
      ) : (
        // eslint-disable-next-line @next/next/no-img-element
        <img alt={description || ''} src={imgxUrl} onError={onError} />
      )}
    </Box>
  );
});

MobileImageVisualizer.displayName = 'MobileImageVisualizer';
