import { Images, Refresh } from '@air/next-icons';
import { Box } from '@air/zephyr';
import { memo } from 'react';

import { ASSET_MODAL_PROCESSING_TEXT } from '~/constants/testIDs';

interface NoPreviewVisualizerProps {
  isProcessing?: boolean;
}

export const NoPreviewVisualizer = memo(({ isProcessing }: NoPreviewVisualizerProps) => {
  return (
    <Box
      data-testid={ASSET_MODAL_PROCESSING_TEXT}
      tx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: [380, 380, 0],
      }}
    >
      {isProcessing ? (
        <Refresh className="h-8 w-8 animate-spin fill-pigeon-400 motion-reduce:animate-none" />
      ) : (
        <Box
          as={Images}
          tx={{
            width: [64, 64, 64],
            color: 'pigeon400',
            height: [64, 64, 64],
          }}
        />
      )}
      <div className="mt-4 text-14 text-grey-11 md:mt-6 md:text-16">
        {isProcessing ? 'Processing... this may take a few minutes' : 'No preview available'}
      </div>
    </Box>
  );
});

NoPreviewVisualizer.displayName = 'NoPreviewVisualizer';
