import { useBreakpointsContext } from '@air/provider-media-query';
import { TXProp } from '@air/zephyr';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isNil } from 'lodash';
import { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { DiscussionForm, MentionsData } from '~/components/Discussions/DiscussionForm';
import { NewDiscussionActions } from '~/components/Discussions/InitialCommentInput/components/NewDiscussionActions';
import { ADD_DISCUSSION_COMMENT_INPUT } from '~/constants/testIDs';
import { useNoPreviewInVisualizer } from '~/hooks/useNoPreviewInVisualizer';
import { useAnnotationContextSelector } from '~/providers/AnnotationProvider/AnnotationProvider';
import { newAnnotationInfoSelector } from '~/providers/AnnotationProvider/annotationProviderUtils';
import { useVideoTimestampContext } from '~/providers/VideoTimestampProvider';
import { centralizedClipStatusSelector } from '~/store/centralizedClip/selectors';
import { activeTimestampParamSelector } from '~/store/router/selectors';
import { isMobileAgent } from '~/utils/PlatformHelpers';

interface InitialCommentInputProps {
  /**
   * This is used for the edit state, if this prop exists, the form will
   * populate with the existing comment value.
   */
  comment?: string;

  /**
   * Determines whether or not the cancel button is visible next to the submit button,
   */
  hasCancelButton?: boolean;

  /**
   * An array of users that will populate the mentions menu
   * when a user types the trigger `@`.
   * @see https://github.com/signavio/react-mentions
   */
  mentionsList: MentionsData[];

  /**
   * onCancel event that fires when the user clicks on the cancel button.
   */
  onCancel?: () => void;

  /**
   * onSubmit event that fires when the user clicks on the submit button.
   */
  onSubmit: (comment: string, timestamp?: number) => void;

  /**
   * The placeholder text for the discussions form input field.
   */
  placeholder?: string;

  tx?: TXProp;
  shouldShowMentionsButton?: boolean;
  hasInternalAvatar?: boolean;
  canAnnotateClip: boolean;
}

export const InitialCommentInput = memo(
  ({
    comment,
    hasCancelButton,
    mentionsList,
    onSubmit,
    onCancel,
    placeholder,
    shouldShowMentionsButton,
    hasInternalAvatar,
    canAnnotateClip,
  }: InitialCommentInputProps) => {
    const { videoTimestamp: hasVideoTimestampFlag } = useFlags();
    const { isAboveMediumScreen } = useBreakpointsContext();
    const activeTimestamp = useSelector(activeTimestampParamSelector);
    const { currentTime } = useVideoTimestampContext();

    const clipStatus = useSelector(centralizedClipStatusSelector);

    const newAnnotation = useAnnotationContextSelector(newAnnotationInfoSelector);

    const [shouldEnableTimestamps, setShouldEnableTimestamps] = useState(false);

    const isDesktop = isAboveMediumScreen && !isMobileAgent;

    const { noPreviewInVisualizer } = useNoPreviewInVisualizer();

    useEffect(() => {
      if (hasVideoTimestampFlag) {
        setShouldEnableTimestamps(true);
      }
    }, [hasVideoTimestampFlag]);

    useEffect(() => {
      if (!hasVideoTimestampFlag) {
        setShouldEnableTimestamps(!!newAnnotation);
      }
    }, [hasVideoTimestampFlag, newAnnotation]);

    useEffect(() => {
      if (activeTimestamp) {
        setShouldEnableTimestamps(hasVideoTimestampFlag || !isNil(activeTimestamp));
      }
    }, [activeTimestamp, clipStatus, hasVideoTimestampFlag]);

    const handleCommentSubmit = useCallback(
      (comment: string) => {
        onSubmit(
          comment,
          isDesktop && shouldEnableTimestamps ? (hasVideoTimestampFlag ? currentTime : activeTimestamp) : undefined,
        );
      },
      [activeTimestamp, hasVideoTimestampFlag, isDesktop, onSubmit, shouldEnableTimestamps, currentTime],
    );

    return (
      <DiscussionForm
        data-testid={ADD_DISCUSSION_COMMENT_INPUT}
        comment={comment}
        hasCancelButton={hasCancelButton}
        mentionsList={mentionsList}
        onCancel={onCancel}
        onSubmit={handleCommentSubmit}
        placeholder={placeholder}
        shouldShowMentionsButton={shouldShowMentionsButton}
        hasInternalAvatar={hasInternalAvatar}
        leftActions={
          <NewDiscussionActions
            canAnnotateClip={canAnnotateClip}
            shouldEnableTimestamps={shouldEnableTimestamps}
            setShouldEnableTimestamps={setShouldEnableTimestamps}
            shouldDisableTimestampsAndAnnotations={noPreviewInVisualizer}
          />
        }
      />
    );
  },
);

InitialCommentInput.displayName = 'InitialCommentInput';
