import { Account } from '@air/api/types';
import { Button, TXProp } from '@air/zephyr';
import { memo } from 'react';

import { Avatar } from '~/components/Avatar';
import { TIMESTAMP_MARKER, TIMESTAMP_MARKER_AVATAR } from '~/constants/testIDs';

import { MARKER_CONTAINER_WIDTH } from '../shared/constants';

export interface TimestampMarkerProps {
  userAvatar?: string;
  tx?: TXProp;
  commenterId?: Account['id'];
  onClick: () => void;
  text?: string;
  count?: number;
}

export const TimestampMarker = memo(
  ({ userAvatar, tx, onClick, text, commenterId, count = 1 }: TimestampMarkerProps) => {
    return (
      <Button
        tx={{
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          bottom: 6,
          width: MARKER_CONTAINER_WIDTH,
          '&:hover': { backgroundColor: 'transparent', zIndex: 1 },
          ...tx,
        }}
        variant="button-unstyled"
        onClick={onClick}
        data-testid={TIMESTAMP_MARKER}
      >
        <div className="group/timestamp-marker relative">
          <Avatar
            data-testid={`${TIMESTAMP_MARKER_AVATAR}-${commenterId}`}
            className="pointer-events-none border border-peacock-300 group-hover/timestamp-marker:h-5 group-hover/timestamp-marker:w-5 group-hover/timestamp-marker:border-white"
            size={16}
            src={userAvatar || undefined}
            text={text}
            colorSeed={commenterId}
          />
          {count > 1 && (
            <div className="pointer-events-auto absolute bottom-0 left-0 flex h-4 w-4 items-center justify-center rounded-full border border-peacock-300 bg-pigeon-900/60 group-hover/timestamp-marker:h-5 group-hover/timestamp-marker:w-5 group-hover/timestamp-marker:border-white">
              <div className="text-8 font-bold text-white">{count < 10 ? `+${count - 1}` : '9+'}</div>
            </div>
          )}
        </div>

        <div className="mt-1 h-1 w-0.5 bg-white" />
      </Button>
    );
  },
);

TimestampMarker.displayName = 'TimestampMarker';
