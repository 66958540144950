import { AnnotationType, Line } from '@air/api/types';
import { useMemoizedContextProvider, useMemoizedContextSelector } from '@air/react-memoized-context';
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { FreeformAnnotation } from '~/components/Annotations/shared/types';
import { QueryParamNames } from '~/constants/search';
import {
  AnnotationContextType,
  AnnotationContextValue,
  AnnotationProviderActions,
  defaultValue,
} from '~/providers/AnnotationProvider/annotationProviderTypes';
import { annotationsDispatch } from '~/providers/AnnotationProvider/annotationProviderUtils';
import { centralizedClipIdSelector } from '~/store/centralizedClip/selectors';
import { activeTimestampParamSelector, isViewingAnnotatedCommentSelector } from '~/store/router/selectors';
import { isDevOrTestStage } from '~/swr-hooks/utils';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';
import { setQueryParams } from '~/utils/PathUtils';

interface AnnotationProviderProps {
  children: ReactNode;
}

const AnnotationContext = createContext<AnnotationContextType>(defaultValue);

export const AnnotationProvider = ({ children }: AnnotationProviderProps) => {
  const { contextValue } = useMemoizedContextProvider<AnnotationContextValue>(
    {
      annotationType: 'box',
      annotationColor: 'flamingo400',
      newAnnotation: undefined,
      activeAnnotation: undefined,
      annotationSize: 3,
      isAnnotationsEnabled: false,
      revertedLines: [],
    },
    annotationsDispatch,
  );

  const setAnnotationType = useCallback(
    (type: AnnotationType) => {
      setQueryParams({ [QueryParamNames.discussionId]: null }, 'replace');
      return contextValue.dispatch({ type: AnnotationProviderActions.setAnnotationType, data: type });
    },
    [contextValue],
  );

  const setAnnotationColor = useCallback(
    (color: string) => contextValue.dispatch({ type: AnnotationProviderActions.setAnnotationColor, data: color }),
    [contextValue],
  );

  const setAnnotationSize = useCallback(
    (size: number) => contextValue.dispatch({ type: AnnotationProviderActions.setAnnotationSize, data: size }),
    [contextValue],
  );

  const setAnnotationsEnabled = useCallback(
    (enabled: boolean) =>
      contextValue.dispatch({ type: AnnotationProviderActions.setAnnotationsEnabled, data: enabled }),
    [contextValue],
  );

  const setNewAnnotation = useCallback(
    (info: FreeformAnnotation | undefined) =>
      contextValue.dispatch({ type: AnnotationProviderActions.setNewAnnotation, data: info }),
    [contextValue],
  );

  const setActiveAnnotation = useCallback(
    (annotation?: FreeformAnnotation) =>
      contextValue.dispatch({ type: AnnotationProviderActions.setActiveAnnotation, data: annotation }),
    [contextValue],
  );

  const addRevertedLine = useCallback(
    (line: Line) => contextValue.dispatch({ type: AnnotationProviderActions.addRevertedLine, data: line }),
    [contextValue],
  );

  const redoLine = useCallback(
    () => contextValue.dispatch({ type: AnnotationProviderActions.redoLine }),
    [contextValue],
  );

  const clearRevertedLines = useCallback(
    () => contextValue.dispatch({ type: AnnotationProviderActions.clearRevertedLines }),
    [contextValue],
  );

  const clearNewAnnotation = useCallback(
    () => contextValue.dispatch({ type: AnnotationProviderActions.clearNewAnnotation }),
    [contextValue],
  );

  const undo = useCallback(() => {
    const { newAnnotation } = contextValue.getValue();
    if (newAnnotation && newAnnotation.type === 'pencil' && newAnnotation.lines.length > 0) {
      const revertedLine = newAnnotation.lines[newAnnotation.lines.length - 1];
      addRevertedLine(revertedLine);
      const newAnnotationLines = newAnnotation.lines.slice(0, -1);
      setNewAnnotation({
        ...newAnnotation,
        lines: newAnnotationLines,
      });
    }
  }, [addRevertedLine, contextValue, setNewAnnotation]);

  const redo = useCallback(() => {
    const { newAnnotation, revertedLines, annotationSize, annotationColor } = contextValue.getValue();
    if (newAnnotation && newAnnotation.type === 'pencil' && revertedLines.length > 0) {
      const revertedLine = revertedLines[revertedLines.length - 1];
      redoLine();

      setNewAnnotation({
        ...newAnnotation,
        lines: [...newAnnotation.lines, { ...revertedLine, lineWidth: annotationSize, color: annotationColor }],
      });
    }
  }, [contextValue, redoLine, setNewAnnotation]);

  const centralizedClipId = useSelector(centralizedClipIdSelector);
  const isViewingComment = useSelector(isViewingAnnotatedCommentSelector);
  const activeTimestamp = useSelector(activeTimestampParamSelector);

  useEffect(() => {
    clearNewAnnotation();
  }, [centralizedClipId, activeTimestamp, setNewAnnotation, clearNewAnnotation]);

  useEffect(() => {
    if (isViewingComment) {
      clearNewAnnotation();
    }
  }, [clearNewAnnotation, isViewingComment, setActiveAnnotation, setNewAnnotation]);

  const value: AnnotationContextType = useMemo(
    () => ({
      ...contextValue,
      setNewAnnotation,
      setActiveAnnotation,
      setAnnotationType,
      setAnnotationColor,
      setAnnotationSize,
      clearNewAnnotation,
      undo,
      setAnnotationsEnabled,
      redo,
      clearRevertedLines,
      addRevertedLine,
    }),
    [
      addRevertedLine,
      clearNewAnnotation,
      clearRevertedLines,
      contextValue,
      redo,
      setActiveAnnotation,
      setAnnotationColor,
      setAnnotationSize,
      setAnnotationType,
      setAnnotationsEnabled,
      setNewAnnotation,
      undo,
    ],
  );

  return <AnnotationContext.Provider value={value}>{children}</AnnotationContext.Provider>;
};

export function useAnnotationContext() {
  const context = useContext(AnnotationContext);

  if (context === defaultValue) {
    const error = 'AnnotationContext used outside of AnnotationProvider';
    if (isDevOrTestStage()) {
      throw error;
    } else {
      reportErrorToBugsnag({
        error,
        context: error,
      });
    }
  }

  return context;
}

export function useAnnotationContextSelector<T>(selector: (st: AnnotationContextValue) => T) {
  const context = useAnnotationContext();
  return useMemoizedContextSelector(context, selector);
}
