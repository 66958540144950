import { Box } from '@air/zephyr';
import { memo } from 'react';

import FixedWidthText from '~/components/FixedWidthText';
import { TIME_ELAPSED_CLOCK } from '~/constants/testIDs';

export interface TimeElapsedClockProps {
  elapsedText: string;
  durationText: string;
  maxText: string;
}
export const TimeElapsedClock = memo(({ elapsedText, durationText, maxText }: TimeElapsedClockProps) => {
  return (
    <Box data-testid={TIME_ELAPSED_CLOCK} tx={{ display: 'flex', alignItems: 'center' }}>
      <FixedWidthText
        data-testid="CURRENT_VIDEO_TIME"
        tx={{ color: 'pigeon050', fontSize: 14, textAlign: 'right' }}
        maxText={maxText}
        text={elapsedText}
      />
      <div className="px-1 text-14 font-medium text-grey-10">/</div>
      <FixedWidthText
        data-testid="VIDEO_TIME_LEFT"
        tx={{ color: 'pigeon400', fontSize: 14, fontWeight: 'medium' }}
        maxText={maxText}
        text={durationText}
      />
    </Box>
  );
});

TimeElapsedClock.displayName = 'TimeElapsedClock';
