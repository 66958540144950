import { useBreakpointsContext } from '@air/provider-media-query';
import { Box } from '@air/zephyr';
import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { FileExtensionName, fileExtensionStylingMap, genericFile } from '~/components/FileExtensionPreview/types';
import { CLIP_PREVIEW_IMAGE } from '~/constants/testIDs';
import {
  centralizedClipExtSelector,
  centralizedClipFormattedSizeSelector,
  centralizedDisplayTitleSelector,
} from '~/store/centralizedClip/selectors';

export const FileVisualizer = memo(() => {
  const ext = useSelector(centralizedClipExtSelector);
  const title = useSelector(centralizedDisplayTitleSelector);
  const formattedSize = useSelector(centralizedClipFormattedSizeSelector);
  const { isAboveMediumScreen } = useBreakpointsContext();

  const extStyles = useMemo(() => {
    const normalizedExt = ext?.toLowerCase() || ''; // to support e.g. JPG
    return normalizedExt in fileExtensionStylingMap
      ? fileExtensionStylingMap[normalizedExt as FileExtensionName]
      : genericFile;
  }, [ext]);

  return (
    <Box
      data-testid={CLIP_PREVIEW_IMAGE}
      tx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: [380, 380, 0],
      }}
    >
      {/* ICON */}
      <Box
        tx={{
          width: [64, 64, 100],
          height: [64, 64, 100],
          borderRadius: [8, 8, 12],
          backgroundColor: extStyles.bgColor,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: extStyles.iconColor,
        }}
      >
        <Box
          as={extStyles.iconComponent}
          tx={{ width: isAboveMediumScreen ? 64 : 40, color: 'currentColor', display: 'block' }}
        />
      </Box>
      <div className="mt-4 text-18 font-medium text-grey-12 md:mt-6 md:text-24">{title}</div>
      <div className="mt-1 text-14 text-grey-10 md:mt-2 md:text-16">{`${ext} ∙ ${formattedSize}`}</div>
    </Box>
  );
});

FileVisualizer.displayName = 'FileVisualizer';
