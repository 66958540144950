import { Plus } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { Box } from '@air/zephyr';
import classNames from 'classnames';
import { memo, ReactNode } from 'react';

import { ADD_CUSTOM_FIELD_MINI_BUTTON, CUSTOM_FIELD_LIST, CUSTOM_FIELD_PANEL } from '~/constants/testIDs';

export interface CustomFieldsPanelProps {
  onCreateClick?: () => void;
  fields: ReactNode;
}

export const CustomFieldsPanel = memo(({ onCreateClick, fields }: CustomFieldsPanelProps) => {
  return (
    <Box
      data-testid={CUSTOM_FIELD_PANEL}
      tx={{
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        position: 'relative',
        height: '100%',
      }}
    >
      <Box
        data-testid={CUSTOM_FIELD_LIST}
        className={classNames({ isEditable: !!onCreateClick })}
        tx={{
          width: '100%',
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          py: 16,
          pr: 16,
          pl: 15,
          flex: 1,
          overflow: 'auto',
        }}
      >
        {fields}
      </Box>
      {!!onCreateClick && (
        <div className="flex h-16 w-full shrink-0 items-center justify-start border-t border-t-grey-4 bg-grey-1 pl-4">
          <Button
            appearance="ghost"
            color="grey"
            className="w-full justify-start"
            data-testid={ADD_CUSTOM_FIELD_MINI_BUTTON}
            onClick={onCreateClick}
            prefix={<Plus className="h-4 w-4" />}
            size="large"
          >
            Add custom field
          </Button>
        </div>
      )}
    </Box>
  );
});

CustomFieldsPanel.displayName = 'CustomFieldsPanel';
