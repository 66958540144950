import { Box, TXProp } from '@air/zephyr';
import { memo, PropsWithChildren, ReactNode } from 'react';

export interface AssetModalFooterProps {
  header: ReactNode;
  tx?: TXProp;
}

export const AssetModalFooter = memo(({ header, children, tx }: PropsWithChildren<AssetModalFooterProps>) => {
  return (
    <Box
      tx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        alignItem: 'center',
        justifyContent: 'center',
        position: 'relative',
        flexShrink: 0,
        ...tx,
      }}
    >
      {children}
      <div className="absolute bottom-0 left-0 flex h-16 w-full items-center justify-center border-t border-t-grey-5 bg-grey-1 px-4">
        {header}
      </div>
    </Box>
  );
});

AssetModalFooter.displayName = 'AssetModalFooter';
