import { DoubleChevronLeft, DoubleChevronRight, Play, Redo, VolumeHigh, VolumeLow, VolumeMuted } from '@air/next-icons';
import { memo } from 'react';

import { KeyShortcut } from '~/components/AssetModal/Panel/Tabs/KeyboardShortcutsInfoButton/KeyboardShortcutsInfoModal/shared/KeyShortcut';
import { DownArrowShortcut, UpArrowShortcut } from '~/components/KeyboardShortcuts';
import { ShortcutInfo } from '~/components/ShortcutInfo';

export const VideoShortcutsInfo = memo(() => (
  <>
    <KeyShortcut icon={Play} text="Play/Pause video">
      <ShortcutInfo>SPACE BAR</ShortcutInfo>
      <span className="mx-0.5 text-12 text-pigeon-400">or</span>
      <ShortcutInfo>k</ShortcutInfo>
    </KeyShortcut>
    <KeyShortcut icon={DoubleChevronRight} text="Jump 10s forward">
      <ShortcutInfo>j</ShortcutInfo>
    </KeyShortcut>
    <KeyShortcut icon={DoubleChevronLeft} text="Jump 10s backward">
      <ShortcutInfo>l</ShortcutInfo>
    </KeyShortcut>
    <KeyShortcut icon={VolumeMuted} text="Mute">
      <ShortcutInfo>m</ShortcutInfo>
    </KeyShortcut>
    <KeyShortcut icon={VolumeHigh} text="Increase volume">
      <UpArrowShortcut />
    </KeyShortcut>
    <KeyShortcut icon={VolumeLow} text="Decrease volume">
      <DownArrowShortcut />
    </KeyShortcut>
    <KeyShortcut icon={Redo} text="Jump to… (10% increments)">
      <ShortcutInfo>1</ShortcutInfo>
      <span className="text-12 text-pigeon-400">…</span>
      <ShortcutInfo>9</ShortcutInfo>
    </KeyShortcut>
  </>
));

VideoShortcutsInfo.displayName = 'BasicShortcutsInfo';
