import { Box } from '@air/zephyr';
import classNames from 'classnames';
import { memo, useMemo } from 'react';
import { SharedImigixAndSourceProps } from 'react-imgix';

import { ImgixImage } from '~/components/ImgixImage';
import { getCloudFrontUrlFromImgixUrl } from '~/constants/cloudfront';

import { THUMBNAIL_HEIGHT } from '../../../shared/constants';

const THUMBNAIL_WIDTH = 112;
const IMGX_OPTIONS: SharedImigixAndSourceProps['imgixParams'] = {
  fit: 'crop',
  auto: 'compress',
  w: THUMBNAIL_WIDTH,
  h: THUMBNAIL_HEIGHT,
};

interface VersionImageThumbnailProps {
  src: string;
  isCurrentVersion: boolean;
}

export const VersionImageThumbnail = memo(({ src, isCurrentVersion }: VersionImageThumbnailProps) => {
  const fallbackUrl = useMemo(() => {
    if (src) return getCloudFrontUrlFromImgixUrl(src);
    return '';
  }, [src]);

  return (
    <Box
      className={classNames('asset-modal-version-thumbnail', { isCurrentVersion })}
      tx={{
        pointerEvents: 'none',
        width: '100%',
        height: THUMBNAIL_HEIGHT,
        borderRadius: 2,
        border: '1px solid black',
        boxSizing: 'border-box',
        overflow: 'hidden',
        ['&.isCurrentVersion']: {
          boxShadow: '0px 0px 0px 2px #CECECE',
        },
        img: {
          pointerEvents: 'none',
          objectFit: 'cover',
        },
      }}
    >
      <ImgixImage
        alt=""
        src={src}
        fallbackUrl={fallbackUrl}
        imgixParams={IMGX_OPTIONS}
        height={THUMBNAIL_HEIGHT}
        width={THUMBNAIL_WIDTH}
      />
    </Box>
  );
});

VersionImageThumbnail.displayName = 'VersionImageThumbnail';
