import { type AssetCustomFieldValue, type ResponseCustomFieldValue } from '@air/api/types';
import { Fields } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { AssetLocationBreadcrumbs } from '~/components/AssetModal/Header/components/AssetLocationBreadcrumbs';
import { AssetLocationName } from '~/components/AssetModal/Header/components/AssetLocationName';
import { CustomField } from '~/components/CustomField';
import { DiscussionsMenuButton } from '~/components/Discussions/DiscussionsMenu/components/DiscussionsMenuButton';
import { DiscussionsMenu } from '~/components/Discussions/DiscussionsMenu/DiscussionsMenu';
import { ASSET_MODAL_HEADER_FIELDS_BUTTON, DISCUSSIONS_MENU_BTN } from '~/constants/testIDs';
import { AssetModalPanel, useAssetModalPanelContext } from '~/providers/AssetModalPanelContextProvider';
import { centralizedClipBoardInfoSelector } from '~/store/centralizedClip/selectors';

interface HeaderBoardAndFieldsInfoProps {
  onClose?: () => void;
  canEditCustomFields: boolean;
  fields: AssetCustomFieldValue[] | undefined;
  showComments: boolean;
  workspaceName: string | undefined;
  canViewCustomFields: boolean;
}

export const HeaderBoardAndFieldsInfo = memo(
  ({
    onClose,
    canEditCustomFields,
    fields,
    showComments,
    workspaceName,
    canViewCustomFields,
  }: HeaderBoardAndFieldsInfoProps) => {
    const { showPanel } = useAssetModalPanelContext();
    const { libraries: librariesFlag } = useFlags();
    const { current } = useSelector(centralizedClipBoardInfoSelector);

    const fieldsToRender = useMemo<ResponseCustomFieldValue[]>(() => {
      if (!fields || !fields.length) return [];

      return fields.reduce((prev, field) => {
        if (prev.length === 3) return prev;

        if (field.value) return [...prev, field.value];

        if (field.values) {
          const toReturn = prev;
          field.values.forEach((value) => {
            if (toReturn.length < 3) toReturn.push(value);
          });
          return toReturn;
        }
        return prev;
      }, [] as ResponseCustomFieldValue[]);
    }, [fields]);

    const otherFieldsLength = useMemo(() => {
      if (!fields) return;

      let totalCount = 0;

      fields.forEach((field) => {
        if (field.value) {
          totalCount += 1;
        } else if (field.values) {
          totalCount += field.values.length;
        }
      });

      const numOtherFields = totalCount - fieldsToRender.length;

      return numOtherFields < 0 ? undefined : numOtherFields;
    }, [fields, fieldsToRender]);

    return (
      <div className="mt-1.5 flex h-8 w-full items-center justify-start pl-6">
        {current ? (
          current.library && !!current.ancestors?.length && librariesFlag ? (
            <AssetLocationBreadcrumbs />
          ) : (
            <AssetLocationName onClick={onClose} location={current.title} />
          )
        ) : (
          workspaceName && <AssetLocationName location={workspaceName} />
        )}
        {showComments && (
          <div className="flex items-center">
            <p className="ml-1.5 mr-0.5 shrink-0 text-center text-14 text-pigeon-100">・</p>
            <DiscussionsMenu trigger={<DiscussionsMenuButton data-testid={DISCUSSIONS_MENU_BTN} />} />
          </div>
        )}
        {(canEditCustomFields || (fieldsToRender.length > 0 && canViewCustomFields)) && (
          <>
            <p className="mx-0.5 shrink-0 text-center text-14 text-pigeon-100">・</p>
            <Button
              appearance="ghost"
              color="grey"
              className="shrink-0 px-1"
              data-testid={ASSET_MODAL_HEADER_FIELDS_BUTTON}
              onClick={() => showPanel(AssetModalPanel.FIELDS)}
              prefix={<Fields className="h-4 w-4" />}
              size="small"
            >
              {fieldsToRender.length ? (
                <div className="flex items-center gap-1">
                  {fieldsToRender.map((field) => (
                    <CustomField field={field} key={field.id} />
                  ))}
                  <span className="text-12 text-grey-10">{!!otherFieldsLength && `+${otherFieldsLength}`}</span>
                </div>
              ) : (
                'Add custom field'
              )}
            </Button>
          </>
        )}
      </div>
    );
  },
);

HeaderBoardAndFieldsInfo.displayName = 'HeaderBoardAndFieldsInfo';
