import { useTrackAnnotationColorChanged } from '@air/analytics';
import { Checkbox } from '@air/primitive-checkbox';
import classNames from 'classnames';
import { memo } from 'react';

import { ANNOTATION_COLOR_CHECKBOX } from '~/constants/testIDs';

export const ANNOTATION_COLORS: { className: string; id: string }[] = [
  { className: 'bg-cockatoo-200 radix-state-checked:bg-cockatoo-200', id: 'cockatoo200' },
  { className: 'bg-cuckoo-600 radix-state-checked:bg-cuckoo-600', id: 'cuckoo600' },
  { className: 'bg-peacock-400 radix-state-checked:bg-peacock-400', id: 'peacock400' },
  { className: 'bg-canary-300 radix-state-checked:bg-canary-300', id: 'canary300' },
  { className: 'bg-flamingo-400 radix-state-checked:bg-flamingo-400', id: 'flamingo400' },
];

export interface AnnotationColorSelectProps {
  selectedColor: string;
  setColor: (color: string) => void;
}

export const AnnotationColorSelect = memo(({ selectedColor, setColor }: AnnotationColorSelectProps) => {
  const { trackAnnotationColorChanged } = useTrackAnnotationColorChanged();

  return (
    <div className="mr-1 flex items-center gap-1 py-2">
      {ANNOTATION_COLORS.map((color) => (
        <Checkbox
          aria-label={`Annotate using ${color.id} color`}
          checked={selectedColor === color.id}
          className={classNames('border-none', color.className)}
          data-color={color}
          data-testid={`${ANNOTATION_COLOR_CHECKBOX}-${color}`}
          key={color.id}
          onCheckedChange={() => {
            trackAnnotationColorChanged({ color: color.id });
            setColor(color.id);
          }}
          size="extra-large"
        />
      ))}
    </div>
  );
});

AnnotationColorSelect.displayName = 'AnnotationColorSelect';
