import { Box, Text } from '@air/zephyr';
import { FieldInputProps } from 'formik';
import { ReactNode } from 'react';

import { FormikInput } from '~/components/Zephyr/Formik/FormikInput';
import { DIMENSION_BAR_INPUT } from '~/constants/testIDs';

import { DimensionBarFormData, DimensionBarProps, DimensionMeasurement } from '../DimensionBar';

interface DimensionBarInputWrapperProps extends Pick<DimensionBarProps, 'dimensionUnits'> {
  children: ReactNode;
  dimensionMeasurement: DimensionMeasurement;
  onFocus: (isFocused: boolean) => void;
}

const DimensionBarInputWrapper = ({
  children,
  dimensionMeasurement,
  dimensionUnits,
  onFocus,
}: DimensionBarInputWrapperProps) => {
  return (
    <Box tx={{ display: 'flex', alignItems: 'center' }} onFocus={() => onFocus(true)} onBlur={() => onFocus(false)}>
      <Text variant="text-ui-12-uppercase" tx={{ fontWeight: 'bold', color: 'inherit' }}>
        {dimensionMeasurement}
      </Text>
      {children}
      <Text variant="text-ui-16" tx={{ color: 'pigeon600' }}>
        {dimensionUnits}
      </Text>
    </Box>
  );
};

interface DimensionBarInputProps
  extends Pick<DimensionBarInputWrapperProps, 'dimensionMeasurement' | 'dimensionUnits'>,
    Pick<DimensionBarProps, 'disabled'> {
  ['data-testid']?: string;
  onChange: FieldInputProps<DimensionBarFormData['height'] | DimensionBarFormData['width']>['onChange'];
  onFocus: (isFocused: boolean) => void;
}

export const DimensionBarInput = ({
  dimensionMeasurement,
  dimensionUnits,
  disabled,
  onChange,
  onFocus,
  ['data-testid']: testId,
}: DimensionBarInputProps) => {
  return (
    <DimensionBarInputWrapper
      dimensionUnits={dimensionUnits}
      dimensionMeasurement={dimensionMeasurement}
      onFocus={onFocus}
    >
      <FormikInput
        data-testid={testId || DIMENSION_BAR_INPUT}
        disabled={disabled}
        onChange={onChange}
        isErrorHidden
        isLabelHidden
        label={dimensionMeasurement}
        name={dimensionMeasurement}
        required
        spacingStyles={{
          minWidth: 'unset',
          mx: 6,
          width: 64,
        }}
        type="number"
      />
    </DimensionBarInputWrapper>
  );
};
